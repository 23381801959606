import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {MESSAGES_URL, NEWS_URL} from "../../models/const/api";
import { NewsApiDataModel } from '../../models/interfaces/news-model';
import { NewsMessageDetailsModel } from '../../models/interfaces/news-message-details.model';
import { TeacherMessagesApiResponseModel } from '../../models/interfaces/teacher-message.model';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    getNews(): Observable<NewsApiDataModel> {
        return this.httpClient.get<NewsApiDataModel>(NEWS_URL)
    }

    getSpecificNews(slug: string): Observable<NewsMessageDetailsModel> {
        return this.httpClient.get<NewsMessageDetailsModel>(NEWS_URL + '/' + slug)
    }

    getTeacherMessages(): Observable<TeacherMessagesApiResponseModel> {
        return this.httpClient.get<TeacherMessagesApiResponseModel>(MESSAGES_URL)
    }
}
